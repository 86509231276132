import React from  'react';

// components
import Header2 from '../../components/header2'
import PageTitle from '../../components/PageTitle'
import About from '../../components/about'
import Spreads from '../../components/Spreads'
import Symtoms from '../../components/symsoms'
import FaqSection2 from '../../components/Faq2'
import Prevention3 from '../../components/prevention3'
import WorldMap3 from '../../components/WorldMap3'
import TeamSection from '../../components/team'
import FooterSection from '../../components/footer'
import './style.css'

const Aboutpage = () => {
    return(
       <div className="page-wrapper">
           <Header2/>
           <PageTitle subtitle={'About Us'} pgtitle={'About'}/>
           <About/>
           <Symtoms/>
           <Spreads/>
           <WorldMap3/>
           <Prevention3/>
           <FaqSection2/>
           <TeamSection/>
           <FooterSection/>
       </div>
    )
}

export default Aboutpage;