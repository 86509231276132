import React from  'react';

// components
import Header2 from '../../components/header2'
import PageTitle from '../../components/PageTitle'
import ContactSection from '../../components/contactsection'
import FooterSection from '../../components/footer'
import './style.css'

const ContactPage = () => {
    return(
       <div className="page-wrapper">
           <Header2/>
           <PageTitle subtitle={'Contact Us'} pgtitle={'Contact'}/>
           <ContactSection/>
           <FooterSection/>
       </div>
    )
}

export default ContactPage;