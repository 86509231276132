import React from 'react'

import World from '../Map'

import wm1 from '../../images/world-map/01.svg'
import wm2 from '../../images/world-map/02.svg'
import wm3 from '../../images/world-map/03.svg'
import wm4 from '../../images/world-map/04.svg'
import './style.css'

const WorldMap = () => {
    return(
        <section className="world-map">
            <div className="container">
                <div className="row">
                    <div className="col-lg-7">
                        <div className="section-title">
                            <span>Corona affected Country</span>
                            <h3>Confirmed cases of Covid-19(corona) Through the World</h3>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div id="wmap">
                          <World/>
                        </div>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-lg-3">
                        <div className="single-world-status">
                            <div className="icon">
                                <img src={wm1} alt=""/>
                            </div>
                            <div className="content">
                                <p>Confirmed Cases </p>
                                <h3>1,441,128</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="single-world-status">
                            <div className="icon">
                               <img src={wm2} alt=""/>
                            </div>
                            <div className="content">
                                <p>Total Death</p>
                                <h3>82,992</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="single-world-status">
                            <div className="icon">
                                <img src={wm3} alt=""/>
                            </div>
                            <div className="content">
                                <p>Total Recovered</p>
                                <h3>307,819</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="single-world-status">
                            <div className="icon">
                                <img src={wm4} alt=""/>
                            </div>
                            <div className="content">
                                <p>Infected Country</p>
                                <h3>120</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default WorldMap;