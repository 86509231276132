import React from 'react'
import {Link} from 'react-router-dom'
import blog1 from '../../images/blog/recent/img-1.jpg'
import blog2 from '../../images/blog/recent/img-2.jpg'
import blog3 from '../../images/blog/recent/img-3.jpg'
import './style.css'

const BlogSidebar = () => {
    return(
        <div className="widget-area">
            <div className="widget">
                <div className="search-form">
                    <form>
                        <div className="form-group">
                            <input type="search" name="search" className="form-control" placeholder="Seacrh Content..."/>
                            <button type="submit" className="search-btn">
                                <i className="fa fa-search"></i>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <div className="widget categories-widgets">
                <div className="widget-title">
                    <h5>categories</h5>
                </div>
                <ul>
                    <li><Link to="/blogdetails"><span>Cardiology</span><span>(5)</span></Link></li>
                    <li><Link to="/blogdetails"><span>Laboratory</span><span>(10)</span></Link></li>
                    <li><Link to="/blogdetails"><span>Research</span><span>(8)</span></Link></li>
                    <li><Link to="/blogdetails"><span>Dermatologists</span><span>(10)</span></Link></li>
                    <li><Link to="/blogdetails"><span>Neurologists</span><span>(12)</span></Link></li>
                    <li><Link to="/blogdetails"><span>Radiologists</span><span>(6)</span></Link></li>
                </ul>
            </div>
            <div className="widget recent-post-widgets">
                <div className="widget-title">
                    <h5>recent post</h5>
                </div>
                <div className="recent-post">
                    <div className="img">
                        <img src={blog1} alt=""/>
                    </div>
                    <div className="post-content">
                        <h6><Link to="/blogdetails">Duis vel tincidunt lectus maurisenim.</Link></h6>
                        <span>05 April 2020</span>
                    </div>
                </div>
                <div className="recent-post">
                    <div className="img">
                        <img src={blog2} alt=""/>
                    </div>
                    <div className="post-content">
                        <h6><Link to="/blogdetails">Duis vel tincidunt lectus maurisenim.</Link></h6>
                        <span>05 April 2020</span>
                    </div>
                </div>
                <div className="recent-post">
                    <div className="img">
                        <img src={blog3} alt=""/>
                    </div>
                    <div className="post-content">
                        <h6><Link to="/blogdetails">Duis vel tincidunt lectus maurisenim.</Link></h6>
                        <span>05 April 2020</span>
                    </div>
                </div>
            </div>
            <div className="widget social-widgets">
                <div className="widget-title">
                    <h5>Follow Us</h5>
                </div>
                <ul>
                    <li><Link to="/blogdetails"><i className="fa fa-facebook"></i></Link></li>
                    <li><Link to="/blogdetails"><i className="fa fa-twitter"></i></Link></li>
                    <li><Link to="/blogdetails"><i className="fa fa-instagram"></i></Link></li>
                    <li><Link to="/blogdetails"><i className="fa fa-pinterest-p"></i></Link></li>
                    <li><Link to="/blogdetails"><i className="fa fa-linkedin"></i></Link></li>
                </ul>
            </div>
            <div className="widget tags-widgets">
                <div className="widget-title">
                    <h5>tags</h5>
                </div>
                <Link to="/blogdetails">Care</Link>
                <Link to="/blogdetails">Healing</Link>
                <Link to="/blogdetails">Hospital</Link>
                <Link to="/blogdetails">Innovation</Link>
                <Link to="/blogdetails">Medicine</Link>
                <Link to="/blogdetails">Treatment</Link>
            </div>
        </div>
    )
}
export default BlogSidebar;