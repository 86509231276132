import React from 'react'
import './style.css'
import fimg from '../../images/faq/faq-img.png'
import Accordion from 'react-bootstrap/Accordion'
import {Card,Button} from 'react-bootstrap'

const FaqSection = () => {
    return(
        <section className="faq">
            <div className="container">
                <div className="faq-inner">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="section-title text-center">
                                <span>Wirus’s FAQ</span>
                                <h3>Wirus’s Frequently asked Question By People</h3>
                                <p>Donec fringilla est sit amet quam mollis dictum ac vitae enim. Nulla facilisi. Cras molestie, tellus id luctus molestie.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <Accordion defaultActiveKey="0">
                                <Card>
                                    <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                        1. Fringilla est sit amet quam mollis dictum ?
                                    </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="0">
                                    <Card.Body>Suspendisse rhoncus, eros vitae vehicula fringilla, nisl massa semper eros us quis nibh.</Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                    2. Fringilla est sit amet quam mollis dictum ?
                                    </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="1">
                                    <Card.Body>Suspendisse rhoncus, eros vitae vehicula fringilla, nisl massa semper eros us quis nibh</Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="2">
                                    3. Amet quam mollis dictum ecras molestie ?
                                    </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="2">
                                    <Card.Body>Suspendisse rhoncus, eros vitae vehicula fringilla, nisl massa semper eros us quis nibh</Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="3">
                                    4. Fringilla mollis dictum ecras molestie ?
                                    </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="3">
                                    <Card.Body>Suspendisse rhoncus, eros vitae vehicula fringilla, nisl massa semper eros us quis nibh</Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </div>
                        
                        <div className="col-lg-6">
                            <div className="faq-img">
                                <img src={fimg} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default FaqSection;