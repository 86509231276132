import React from  'react';

// components
import Header2 from '../../components/header2'
import PageTitle from '../../components/PageTitle'
import Prevention3 from '../../components/prevention3'
import WorldMap3 from '../../components/WorldMap3'
import FooterSection from '../../components/footer'
import './style.css'

const PreventionPage = () => {
    return(
       <div className="page-wrapper">
           <Header2/>
           <PageTitle subtitle={'Prevention'} pgtitle={'Prevention'}/>
           <Prevention3/>
           <WorldMap3 subclass={'prevention-map'}/>
           <FooterSection/>
       </div>
    )
}

export default PreventionPage;