import React from  'react';

// components
import Header from '../../components/header'
import Hero2 from '../../components/hero2'
import About from '../../components/about'
import Spreads2 from '../../components/Spreads2'
import Symtoms2 from '../../components/symsoms2'
import Prevention from '../../components/prevention'
import WorldMap2 from '../../components/WorldMap2'
import TeamSection2 from '../../components/team2'
import FaqSection from '../../components/Faq'
import BlogSection2 from '../../components/Blog2'
import FooterSection from '../../components/footer'

const Homepage2 = () => {
    return(
       <div className="home-wrapper-2">
           <Header/>
           <Hero2/>
           <Spreads2/>
           <About/>
           <Symtoms2/>
           <Prevention/>
           <WorldMap2/>
           <TeamSection2/>
           <FaqSection/>
           <BlogSection2/>
           <FooterSection/>
       </div>
    )
}

export default Homepage2;