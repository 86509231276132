import React from 'react'
import {Link} from 'react-router-dom'
import tm1 from '../../images/team/01.png'
import tm2 from '../../images/team/02.png'
import tm3 from '../../images/team/03.png'
import './style.css'

const TeamSection = () => {
    return(
        <section className="team">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="section-title">
                            <span>Our Team</span>
                            <h3>Our Expert Team who can help you to get any suggestion.</h3>
                            <p>Duis vel tincidunt lectus. Vestibulum condimentum mauris enim, feugiat aliquet lacus venenatis eget.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-lg-0 mb-4">
                        <div className="single-member">
                            <div className="img">
                                <img src={tm1} alt=""/>
                            </div>
                            <div className="content">
                                <h4>Dr. Willium smith</h4>
                                <p>Consultant</p>
                                <ul>
                                    <li><Link to="/"><i className="fa fa-facebook"></i></Link></li>
                                    <li><Link to="/"><i className="fa fa-twitter"></i></Link></li>
                                    <li><Link to="/"><i className="fa fa-instagram"></i></Link></li>
                                    <li><Link to="/"><i className="fa fa-linkedin"></i></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mb-lg-0 mb-4">
                        <div className="single-member">
                            <div className="img">
                                <img src={tm2} alt=""/>
                            </div>
                            <div className="content">
                                <h4>Dr. Saira Doen</h4>
                                <p>Consultant</p>
                                <ul>
                                    <li><Link to="/"><i className="fa fa-facebook"></i></Link></li>
                                    <li><Link to="/"><i className="fa fa-twitter"></i></Link></li>
                                    <li><Link to="/"><i className="fa fa-instagram"></i></Link></li>
                                    <li><Link to="/"><i className="fa fa-linkedin"></i></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 ">
                        <div className="single-member">
                            <div className="img">
                                <img src={tm3} alt=""/>
                            </div>
                            <div className="content">
                                <h4>Dr. Taylor Swift</h4>
                                <p>Consultant</p>
                                <ul>
                                    <li><Link to="/"><i className="fa fa-facebook"></i></Link></li>
                                    <li><Link to="/"><i className="fa fa-twitter"></i></Link></li>
                                    <li><Link to="/"><i className="fa fa-instagram"></i></Link></li>
                                    <li><Link to="/"><i className="fa fa-linkedin"></i></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default TeamSection;