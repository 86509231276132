import React from 'react';
import {BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Homepage from '../HomePage'
import Homepage2 from '../HomePage2'
import Homepage3 from '../HomePage3'
import Aboutpage from '../AboutPage'
import PreventionPage from '../Prevention'
import LIveUpdate from '../LIveUpdate'
import ContactPage from '../ContactPage'
import BlogPage from '../BlogPage'
import BlogPageLeft from '../BlogPageLeft'
import BlogPageright from '../BlogPageright'
import BlogDetailsPage from '../BlogPageDetails'
import BlogPageDetailsRight from '../BlogPageDetailsRight'
import EoorPage from '../EoorPage'

const AllRoute = () => { 

  return (
    <div className="App">
       <Router>
          <Switch>
            <Route exact path='/' component={Homepage} />
            <Route path='/home' component={Homepage} />
            <Route path='/home2' component={Homepage2} />
            <Route path='/home3' component={Homepage3} />
            <Route path='/about' component={Aboutpage} />
            <Route path='/prevention' component={PreventionPage} />
            <Route path='/live' component={LIveUpdate} />
            <Route path='/contact' component={ContactPage} />
            <Route path='/blog' component={BlogPage} />
            <Route path='/blogleft' component={BlogPageLeft} />
            <Route path='/blogright' component={BlogPageright} />
            <Route path='/blogdetails' component={BlogDetailsPage} />
            <Route path='/blogrightdetails' component={BlogPageDetailsRight} />
            <Route path='/error' component={EoorPage} />
          </Switch>
      </Router>
      
    </div>
  );
}

export default AllRoute;
