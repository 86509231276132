import React from 'react'
import {Link} from 'react-router-dom'
import heroimg from '../../images/Virus.png'
import bimg from '../../images/hero-shape.svg'
import './style.css'

const Hero1 = () => {
    return(
        <section className="wpo-hero-style-2" >
            <div className="wpo-slide-wrap">
                <div className="container">
                    <div className="row">
                        <div className="col col-lg-7 col-md-12 slide-caption">
                            <h5>COVID-19</h5>
                            <h2 className="wow fadeInLeftSlow" data-wow-delay="1.0s">Corona Virus Disease Is Infectious,We Sould Avoid Close Contact.</h2>
                            <p className="wow fadeInUp" data-wow-delay="1.5s">Donec fringilla est sit amet quam mollis dictum ac vitae enim. Nulla facilisi. Molestie, tellus id luctus molestie, felis nunc condimentum nulla, at vehicula nibh nulla ac ante. quisque.</p>
                            <Link to='/' className="theme-btn">Learn More</Link>
                        </div>
                    </div>
                </div>
                <div className="wpo-slide-right-img">
                    <div className="slide-img wow fadeInRightSlow" data-wow-duration="2000ms">
                        <img src={heroimg} alt=""/>
                    </div>
                </div>
            </div>
            <div className="hero-bottom-img">
                <img src={bimg} alt=""/>
            </div>
        </section>
    )
}
export default Hero1;