import React from 'react'
import {Link} from 'react-router-dom'
import blog1 from '../../images/blog/01.jpg'
import blog2 from '../../images/blog/01.jpg'
import blog3 from '../../images/blog/01.jpg'
import blog4 from '../../images/blog/01.jpg'
import './style.css'

const BlogSection = () => {
    return(
        <section className="blog">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5">
                        <div className="section-title">
                            <span>Our Blog</span>
                            <h3>Check Recent News about Covid-19(corona).</h3>
                            <p>Duis vel tincidunt lectus. Vestibulum condimentum mauris enim, feugiat.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 mb-4">
                        <div className="single-blog">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="img">
                                        <img src={blog1} alt=""/>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="contact">
                                        <span>05 April 2020</span>
                                        <h5><Link to="/blog">Duis vel tincidunt lectus maurisenim.</Link></h5>
                                        <p>Donec fringlla est ametdis quam quam dictum vitae enim nulla falis Donec quam fringilla Donec fringilla quam.</p>
                                        <Link to="/blog;">Read More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mb-4">
                        <div className="single-blog">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="img">
                                        <img src={blog2} alt=""/>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="contact">
                                        <span>05 April 2020</span>
                                        <h5><Link to="/blog">Duis vel tincidunt lectus maurisenim.</Link></h5>
                                        <p>Donec fringlla est ametdis quam quam dictum vitae enim nulla falis Donec quam fringilla Donec fringilla quam.</p>
                                        <Link to="/blog;">Read More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mb-lg-0 mb-4">
                        <div className="single-blog">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="img">
                                        <img src={blog3} alt=""/>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="contact">
                                        <span>05 April 2020</span>
                                        <h5><Link to="/blog">Duis vel tincidunt lectus maurisenim.</Link></h5>
                                        <p>Donec fringlla est ametdis quam quam dictum vitae enim nulla falis Donec quam fringilla Donec fringilla quam.</p>
                                        <Link to="/blog;">Read More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="single-blog">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="img">
                                        <img src={blog4} alt=""/>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="contact">
                                        <span>05 April 2020</span>
                                        <h5><Link to="/blog">Duis vel tincidunt lectus maurisenim.</Link></h5>
                                        <p>Donec fringlla est ametdis quam quam dictum vitae enim nulla falis Donec quam fringilla Donec fringilla quam.</p>
                                        <Link to="/blog;">Read More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default BlogSection;