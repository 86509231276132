import React from  'react';
import {Link} from 'react-router-dom'
// components
import './style.css'

const EoorPage = () => {
    return(
       <div className="page-wrapper">
            <section className="error">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 text-center">
                            <h1>404</h1>
                            <p>!! opps page not found</p>
                            <Link to="/" className="theme-btn">back home</Link>
                        </div>
                    </div>
                </div>
            </section>
       </div>
    )
}

export default EoorPage;