import React from 'react'
import {Link} from 'react-router-dom'
import blog1 from '../../images/blog/single.jpg'
import blog2 from '../../images/author/img.jpg'
import BlogSidebar from '../BlogSidebar'

const BlogdetailsRight = () => {

    const submitHandler = ( e ) => {
        e.preventDefault()
   }
    return(
        <section className="blog blog-sidebar">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8 order-lg-0 order-0">
                        <div className="single-contact">
                            <div className="img">
                                <img src={blog1} alt=""/>
                            </div>
                            <h5>Duis vel tincidunt lectus maurisenim.</h5>
                            <span>05 April 2020</span>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium fugiat natus atque tempora modi asperiores nisi ipsa quaerat nemo veniam omnis officia rem provident magnam quae repellat quisquam, cum animi totam corporis, sunt minima quibusdam. Libero quia iusto saepe architecto blanditiis odio atque tempore enim, mollitia ratione, eveniet pariatur ducimus sit, quibusdam aliquid non vero nam. Laudantium numquam, nihil eos ullam voluptatum iusto doloremque distinctio perspiciatis minima quasi, nisi natus soluta deserunt officia aspernatur odit id saepe provident dolorem laborum quae magnam. Autem praesentium veniam voluptatem explicabo ipsam ipsum, rerum nulla, natus quas molestias nihil officiis pariatur harum eos minima adipisci debitis unde. Architecto est dignissimos delectus mollitia at. Sunt totam mollitia numquam harum nihil ipsa similique.</p>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iste ducimus, voluptatem quidem ratione explicabo cupiditate totam doloribus molestias, repudiandae, aperiam quas cum tempora voluptas consectetur sint magnam perferendis perspiciatis, eum mollitia illo delectus rem provident odio odit. Explicabo, repellat. Dolores nemo dignissimos, aperiam magni nulla rem distinctio sed voluptas, sint.</p>
                            <blockquote className="blockquote">
                                <p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p>
                                <footer className="blockquote-footer">
                                    Someone famous in <cite title="Source Title">Source Title</cite>
                                </footer>
                            </blockquote>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Hic distinctio, beatae, fuga perferendis accusamus repellat eum deleniti aliquid impedit nemo quis voluptatum dolores earum laborum excepturi commodi, dolore porro ut. Tempora incidunt repellat quia, dicta totam ratione minima deserunt. Labore maiores officia veniam voluptas dignissimos porro amet odit saepe odio nulla soluta inventore molestias veritatis, quibusdam nisi, voluptates est laboriosam, deserunt perspiciatis doloribus natus quasi autem. Ipsum facere, distinctio aliquid repellendus ab a in illum? Iure adipisci aperiam, quos harum est ducimus doloribus repudiandae aliquam exercitationem. Magni dolor et reprehenderit excepturi quod consequuntur inventore obcaecati labore consectetur sit. Ut, rem.</p>
                            <div className="taxonomy-area">
                                <div className="taglist">
                                    <span><i className="fa fa-tags"></i> Tags:</span>
                                    <Link to="/blogdetails">Care</Link>
                                    <Link to="/blogdetails">Healing</Link>
                                    <Link to="/blogdetails">Hospital</Link>
                                    <Link to="/blogdetails">Innovation</Link>
                                    <Link to="/blogdetails">Medicine</Link>
                                    <Link to="/blogdetails">Treatment</Link>
                                </div>
                                <div className="social-share">
                                    <ul>
                                        <li>
                                            <h6>share:</h6>
                                        </li>
                                        <li><Link to="/blogdetails"><i className="fa fa-facebook"></i></Link></li>
                                        <li><Link to="/blogdetails"><i className="fa fa-twitter"></i></Link></li>
                                        <li><Link to="/blogdetails"><i className="fa fa-linkedin"></i></Link></li>
                                        <li><Link to="/blogdetails"><i className="fa fa-instagram"></i></Link></li>
                                        <li><Link to="/blogdetails"><i className="fa fa-rss"></i></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="author-info">
                            <div className="img">
                                <img src={blog2} alt=""/>
                            </div>
                            <div className="content">
                                <div className="content-inner">
                                    <h5>andy rose</h5>
                                    <ul>
                                        <li>follow me:</li>
                                        <li><Link to="/blogdetails"><i className="fa fa-facebook"></i></Link></li>
                                        <li><Link to="/blogdetails"><i className="fa fa-instagram"></i></Link></li>
                                        <li><Link to="/blogdetails"><i className="fa fa-pinterest-p"></i></Link></li>
                                        <li><Link to="/blogdetails"><i className="fa fa-linkedin"></i></Link></li>
                                    </ul>
                                </div>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Omnis alias magnam, veniam. Temporibus mollitia delectus in ipsum, vero voluptatibus harum similique eos id aspernatur voluptas sapiente. Cupiditate adipisci, modi veniam.</p>
                            </div>
                        </div>
                        <div className="comment-area">
                            <h5>2 comments</h5>
                            <div className="comment">
                                <div className="img">
                                    <img src={blog2} alt=""/>
                                </div>
                                <div className="content">
                                    <div className="content-inner">
                                        <h5>andy rose</h5>
                                    </div>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Omnis alias magnam, veniam. Temporibus mollitia delectus in ipsum, vero voluptatibus harum similique eos id aspernatur voluptas sapiente. Cupiditate adipisci, modi veniam.</p>
                                </div>
                            </div>
                            <div className="comment">
                                <div className="img">
                                    <img src={blog2} alt=""/>
                                </div>
                                <div className="content">
                                    <div className="content-inner">
                                        <h5>andy rose</h5>
                                    </div>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Omnis alias magnam, veniam. Temporibus mollitia delectus in ipsum, vero voluptatibus harum similique eos id aspernatur voluptas sapiente. Cupiditate adipisci, modi veniam.</p>
                                </div>
                            </div>
                        </div>
                        <div className="comment-form">
                            <h5>Leave a comment</h5>
                            <form onSubmit={submitHandler}>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Name" name="c-name"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <input type="email" className="form-control" placeholder="Email" name="c-email"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <textarea name="c-comment" id="comment" className="form-control" rows="5" placeholder="Type your comments..."></textarea>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <button type="submit" className="btn c-submit-btn">Submit Comment</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <BlogSidebar/>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default BlogdetailsRight;