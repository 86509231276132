import React from  'react';

// components
import Header2 from '../../components/header2'
import PageTitle from '../../components/PageTitle'
import LiveMAp from '../../components/liveupdate'
import FooterSection from '../../components/footer'
import './style.css'

const LIveUpdate = () => {
    return(
       <div className="page-wrapper">
           <Header2/>
           <PageTitle subtitle={'live update'} pgtitle={'live update'}/>
           <LiveMAp/>
           <FooterSection/>
       </div>
    )
}

export default LIveUpdate;