import React from  'react';

// components
import Header2 from '../../components/header2'
import PageTitle from '../../components/PageTitle'
import BlogSpage from '../../components/Blogpage'
import FooterSection from '../../components/footer'


const BlogPage = () => {
    return(
       <div className="page-wrapper">
           <Header2/>
           <PageTitle subtitle={'Blog'} pgtitle={'Blog'}/>
           <BlogSpage/>
           <FooterSection/>
       </div>
    )
}

export default BlogPage;