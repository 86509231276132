import React from 'react'
import sp1 from '../../images/about/icons/icon1.svg'
import sp2 from '../../images/about/icons/icon2.svg'
import sp3 from '../../images/about/icons/icon3.svg'
import './style.scss'

const Spreads2 = () => {
    return(
        <section className="spreads home-spreads-2">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-3">
                        <div className="section-title">
                            <span>Corona Spreads</span>
                            <h3>How Covid-19 (corona) Spread?</h3>
                            <p>Donec fringilla est sit amet quam et dictum ac vitae enim. Nulla facilis Donec fringilla.</p>
                        </div>
                    </div>
                    <div className="col-lg-9">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="card single-info">
                                    <h4>Human Contact</h4>
                                    <p>Vehicula nibh et nulla ac ante. volutpat quis. Etiam laoreet liberomaximus .</p>
                                    <div className="icon">
                                        <img src={sp1} alt=""/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card single-info">
                                    <h4>Contaminated Object</h4>
                                    <p>Vehicula nibh et nulla ac ante. volutpat quis. Etiam laoreet liberomaximus .</p>
                                    <div className="icon">
                                        <img src={sp2} alt=""/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card single-info">
                                    <h4>Air Transmission</h4>
                                    <p>Vehicula nibh et nulla ac ante. volutpat quis. Etiam laoreet liberomaximus .</p>
                                    <div className="icon">
                                       <img src={sp3} alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Spreads2;