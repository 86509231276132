import React from 'react';
import {Link} from 'react-router-dom'

import vec from '../../images/footer/vector.svg'
import logo from '../../images/logo.png'

import './style.css'

const FooterSection = () => {

    return(
        <footer className="footer">
            <div className="top-footer">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-7">
                            <div className="left-content">
                                <h3>Are You affected By corona?</h3>
                                <p>Donec fringlla est ametdis quam quam dictum vitae enim nulla falis Donec fringilla Donecfringilla quam donec.</p>
                                <Link to="/" className="theme-btn">Appoinment For Test</Link>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-5 offset-lg-2">
                            <img src={vec} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bottom-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2 text-lg-left text-center">
                            <Link className="footer-logo" to="/"><img src={logo}  alt=""/></Link>
                        </div>
                        <div className="col-lg-4 offset-lg-2">
                            <div className="social">
                                <ul>
                                    <li><Link to="/"><i className="fa fa-facebook"></i></Link></li>
                                    <li><Link to="/"><i className="fa fa-twitter"></i></Link></li>
                                    <li><Link to="/"><i className="fa fa-instagram"></i></Link></li>
                                    <li><Link to="/"><i className="fa fa-youtube-play"></i></Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="footer-menu">
                                <ul>
                                    <li><Link to="/">adout</Link></li>
                                    <li><Link to="/">doctor</Link></li>
                                    <li><Link to="/">contact</Link></li>
                                    <li><Link to="/">blog</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copyright">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <p>© 2020 | All Right Reserved By <Link to="/">Wirus</Link></p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
     )
        
}

export default FooterSection;
