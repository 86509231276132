import React from 'react'
import spt1 from '../../images/symptom-icons/01.png'
import spt2 from '../../images/symptom-icons/02.png'
import spt3 from '../../images/symptom-icons/03.png'
import spt4 from '../../images/symptom-icons/04.png'
import spt5 from '../../images/symptom-icons/05.png'
import spt6 from '../../images/symptom-icons/06.png'
import './style.css'

const Symtoms = () => {
    return(
        <section className="symptom">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="section-title">
                            <span>Corona Symptoms</span>
                            <h3>We Should Know About covid-19 (corona) symptoms.</h3>
                            <p>Duis vel tincidunt lectus. Vestibulum condimentum mauris enim, feugiat aliquet lacus venenatis eget.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 mb-4">
                        <div className="single-symptom">
                            <div className="icon">
                                <img src={spt1} alt=""/>
                            </div>
                            <div className="content">
                                <h4>High Fever</h4>
                                <p>Donec fringlla est ametdis quam quam dictum vitae enim nulla falis Donec quam fringilla Donec fringilla quam.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mb-4">
                        <div className="single-symptom">
                            <div className="icon">
                                <img src={spt2} alt=""/>
                            </div>
                            <div className="content">
                                <h4>Headache</h4>
                                <p>Donec fringlla est ametdis quam quam dictum vitae enim nulla falis Donec quam fringilla Donec fringilla quam.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mb-4">
                        <div className="single-symptom">
                            <div className="icon">
                                <img src={spt3} alt=""/>
                            </div>
                            <div className="content">
                                <h4>Shortness Of Breath</h4>
                                <p>Donec fringlla est ametdis quam quam dictum vitae enim nulla falis Donec quam fringilla Donec fringilla quam.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mb-4">
                        <div className="single-symptom">
                            <div className="icon">
                               <img src={spt4} alt=""/>
                            </div>
                            <div className="content">
                                <h4>Sore Throat</h4>
                                <p>Donec fringlla est ametdis quam quam dictum vitae enim nulla falis Donec quam fringilla Donec fringilla quam.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mb-lg-0 mb-4">
                        <div className="single-symptom">
                            <div className="icon">
                                <img src={spt5} alt=""/>
                            </div>
                            <div className="content">
                                <h4>Vomitting</h4>
                                <p>Donec fringlla est ametdis quam quam dictum vitae enim nulla falis Donec quam fringilla Donec fringilla quam.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="single-symptom">
                            <div className="icon">
                                <img src={spt6} alt=""/>
                            </div>
                            <div className="content">
                                <h4>Diarrhea</h4>
                                <p>Donec fringlla est ametdis quam quam dictum vitae enim nulla falis Donec quam fringilla Donec fringilla quam.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Symtoms;