import React from 'react';
import {Link} from 'react-router-dom'
import MobileMenu from '../MobileMenu'
import logo from '../../images/logo.png'

import './style.css'

const Header2 = () => {

    return(
            <div className="header header2">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2 col-md-7 col-sm-5 col-4">
                            <div className="logo">
                                <h2><Link to='/home'><img src={logo} alt=""/></Link></h2>
                            </div>
                        </div>
                        <div className="col-lg-8 d-lg-block d-none">
                            <div className="header-menu">
                                <ul className="mobail-menu d-flex">
                                    <li><Link to='/'>Home</Link>
                                        <ul className="submenu">
                                            <li><Link to='/home'>Home</Link></li>
                                            <li><Link to='/home2'>Home style2</Link></li>
                                            <li><Link to='/home3'>Home style3</Link></li>
                                        </ul>
                                    </li>
                                    <li><Link to='/about'>About</Link></li>
                                    <li><Link to='/'>Pages</Link>
                                        <ul className="submenu">
                                            <li><Link to='/about'>About us</Link></li>
                                            <li><Link to='/live'>Live Update</Link></li>
                                            <li><Link to='/error'>404 Page</Link></li>
                                        </ul>
                                    </li>
                                    <li><Link to='/prevention'>Prevention</Link></li>
                                    <li><Link to='/live'>Live Update</Link></li>
                                    <li><Link to='/contact'>Contact</Link></li>
                                    <li><Link to='/blog'>Blog</Link>
                                        <ul className="submenu">
                                            <li><Link to='/blog'>Blog</Link></li>
                                            <li><Link to='/blogleft'>Blog Sidebar</Link></li>
                                            <li><Link to='/blogdetails'>Blog Details</Link></li>
                                            <li><Link to='/blogrightdetails'>Blog Details Right</Link></li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-6 col-6">
                            <Link to='/' className="theme-btn">Contact Us</Link>
                        </div>
                        <div className="d-lg-none col-md-1 col-sm-1 col-2">
                            <MobileMenu/>
                        </div>
                    </div>
                </div>
             </div>
     )
        
}

export default Header2;
