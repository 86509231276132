import React from 'react'
import pv1 from '../../images/prevention/icon//01.png'
import pv2 from '../../images/prevention/icon//02.png'
import pv3 from '../../images/prevention/icon//03.png'
import pv4 from '../../images/prevention/img.png'
import './style.css'

const Prevention = () => {
    return(
        <section className="prevention">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="section-title">
                            <span>Corona Prevention</span>
                            <h3>there are no specific treatments for <span>COVID-19</span> (C0rona), We Should aware of Prevention.</h3>
                            <p>Donec fringilla est sit amet quam mollis dictum ac vitae enim. Nulla facilisi. Cras molestie, tellus id luctus molestie.</p>
                        </div>
                        <div className="prevention-content">
                            <div className="single-prevention">
                                <div className="icon">
                                   <img src={pv1} alt=""/>
                                </div>
                                <div className="content">
                                    <h4>Wash Your Hand Often</h4>
                                    <p>Fringilla est sit amet quam mollis dictum ac vitae enim nulla facilisi cras molestie, tellus.</p>
                                </div>
                            </div>
                            <div className="single-prevention">
                                <div className="icon">
                                    <img src={pv2} alt=""/>
                                </div>
                                <div className="content">
                                    <h4>Cover Cough & Sneez</h4>
                                    <p>Fringilla est sit amet quam mollis dictum ac vitae enim nulla facilisi cras molestie, tellus.</p>
                                </div>
                            </div>
                            <div className="single-prevention">
                                <div className="icon">
                                   <img src={pv3} alt=""/>
                                </div>
                                <div className="content">
                                    <h4>Avoide Close Contact</h4>
                                    <p>Fringilla est sit amet quam mollis dictum ac vitae enim nulla facilisi cras molestie, tellus.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 offset-lg-1">
                        <img src={pv4} alt=""/>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Prevention;