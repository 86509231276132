import React from 'react'

import {Link} from 'react-router-dom'
import blog1 from '../../images/blog/01.jpg'
import blog2 from '../../images/blog/01.jpg'
import blog3 from '../../images/blog/01.jpg'
import blog4 from '../../images/blog/01.jpg'
import './style.css'

const BlogSpage = () => {
    return(
        <section className="blog blog-sidebar">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 mb-4">
                        <div className="single-blog">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="img">
                                        <img src={blog1} alt=""/>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="contact">
                                        <span>05 April 2020</span>
                                        <h5><Link to="/blog">Duis vel tincidunt lectus maurisenim.</Link></h5>
                                        <p>Donec fringlla est ametdis quam quam dictum vitae enim nulla falis Donec quam fringilla Donec fringilla quam.</p>
                                        <Link to="/blog">Read More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mb-4">
                        <div className="single-blog">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="img">
                                        <img src={blog2} alt=""/>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="contact">
                                        <span>05 April 2020</span>
                                        <h5><Link to="/blog">Duis vel tincidunt lectus maurisenim.</Link></h5>
                                        <p>Donec fringlla est ametdis quam quam dictum vitae enim nulla falis Donec quam fringilla Donec fringilla quam.</p>
                                        <Link to="/blog">Read More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mb-lg-0 mb-4">
                        <div className="single-blog">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="img">
                                        <img src={blog3} alt=""/>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="contact">
                                        <span>05 April 2020</span>
                                        <h5><Link to="/blog">Duis vel tincidunt lectus maurisenim.</Link></h5>
                                        <p>Donec fringlla est ametdis quam quam dictum vitae enim nulla falis Donec quam fringilla Donec fringilla quam.</p>
                                        <Link to="/blog">Read More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="single-blog">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="img">
                                        <img src={blog4} alt=""/>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="contact">
                                        <span>05 April 2020</span>
                                        <h5><Link to="/blog">Duis vel tincidunt lectus maurisenim.</Link></h5>
                                        <p>Donec fringlla est ametdis quam quam dictum vitae enim nulla falis Donec quam fringilla Donec fringilla quam.</p>
                                        <Link to="/blog">Read More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="single-blog">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="img">
                                        <img src={blog4} alt=""/>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="contact">
                                        <span>05 April 2020</span>
                                        <h5><Link to="/blog">Duis vel tincidunt lectus maurisenim.</Link></h5>
                                        <p>Donec fringlla est ametdis quam quam dictum vitae enim nulla falis Donec quam fringilla Donec fringilla quam.</p>
                                        <Link to="/blog">Read More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="single-blog">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="img">
                                        <img src={blog4} alt=""/>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="contact">
                                        <span>05 April 2020</span>
                                        <h5><Link to="/blog">Duis vel tincidunt lectus maurisenim.</Link></h5>
                                        <p>Donec fringlla est ametdis quam quam dictum vitae enim nulla falis Donec quam fringilla Donec fringilla quam.</p>
                                        <Link to="/blog">Read More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <ul className="post-pagin">
                            <li><Link to="/blog"><i className="fa fa-angle-left"></i></Link></li>
                            <li><Link to="/blog">1</Link></li>
                            <li className="active"><Link to="/blog">2</Link></li>
                            <li><Link to="/blog">3</Link></li>
                            <li><Link to="/blog">4</Link></li>
                            <li><Link to="/blog"><i className="fa fa-angle-right"></i></Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default BlogSpage;