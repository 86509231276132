import React from 'react'
import ContactForm from '../../components/ContactForm'
import './style.css'

const ContactSection = ( props ) => {
    return(
        <section className="contact-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 mb-lg-0 mb-4">
                        <div className="contact-address">
                            <div className="contact-info">
                                <div className="icon">
                                    <i className="fa fa-home"></i>
                                </div>
                                <div className="content">
                                    <h5>address</h5>
                                    <p>4834 State Street OOLTEWAH, TN 37363</p>
                                </div>
                            </div>
                            <div className="contact-info">
                                <div className="icon">
                                    <i className="fa fa-phone"></i>
                                </div>
                                <div className="content">
                                    <h5>Phone</h5>
                                    <p>313-927-5913, 423-398-6793</p>
                                </div>
                            </div>
                            <div className="contact-info">
                                <div className="icon">
                                    <i className="fa fa-envelope-o"></i>
                                </div>
                                <div className="content">
                                    <h5>Email</h5>
                                    <p>example@email.com</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="contact-form">
                            <ContactForm/>                        
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default ContactSection;