import React from 'react'
import {Link} from 'react-router-dom'
import './style.css'

const Hero2 = () => {
    return(
        <section className="hero home-two-hero">
            <div className="hero-content">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-12">
                            <span>COVID-19</span>
                            <h1>Corona Virus, All you need to know about symptoms.</h1>
                            <p>Donec fringilla est sit amet quam mollis dictum ac vitae enim. Nulla facilisi. Cras molestie, tellus id luctus molestie, felis nunc condimentum nulla. Quisque ultrices justo liberomaximus.</p>
                            <Link to='/home2' className="theme-btn-s2">learn more</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Hero2;