import React from  'react';

// components
import Header2 from '../../components/header2'
import PageTitle from '../../components/PageTitle'
import BlogRight from '../../components/Blogright'
import FooterSection from '../../components/footer'


const BlogPageright = () => {
    return(
       <div className="page-wrapper">
           <Header2/>
           <PageTitle subtitle={'Blog'} pgtitle={'Blog'}/>
           <BlogRight/>
           <FooterSection/>
       </div>
    )
}

export default BlogPageright;