import React from  'react';

// components
import Header from '../../components/header'
import Hero3 from '../../components/hero3'
import About from '../../components/about'
import Spreads from '../../components/Spreads'
import Symtoms3 from '../../components/symsoms3'
import Prevention2 from '../../components/prevention2'
import WorldMap2 from '../../components/WorldMap2'
import TeamSection2 from '../../components/team2'
import FaqSection from '../../components/Faq'
import BlogSection2 from '../../components/Blog2'
import FooterSection from '../../components/footer'

const Homepage3 = () => {
    return(
       <div className="home-wrapper-3">
           <Header/>
           <Hero3/>
           <About/>
           <Spreads/>
           <Symtoms3/>
           <Prevention2/>
           <WorldMap2/>
           <TeamSection2/>
           <FaqSection/>
           <BlogSection2/>
           <FooterSection/>
       </div>
    )
}

export default Homepage3;