import React from 'react'
import {Link} from 'react-router-dom'
import './style.css'

const Hero3 = () => {
    return(
        <section className="hero home-two-hero3">
            <div className="hero-content">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <span>COVID-19</span>
                            <h1>We Sould Take Necessary Step To Prevent Covid-19 (corona Virus).</h1>
                            <p>Donec fringilla est sit amet quam mollis dictum ac vitae enim. Nulla facilisi. Cras molestie, tellus id luctus molestie, felis nunc condimentum nulla. Quisque ultrices justo liberomaximus.</p>
                            <Link to='/home3' className="theme-btn-s2">learn more</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Hero3;