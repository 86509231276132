import React from  'react';

// components
import Header from '../../components/header'
import Hero1 from '../../components/hero'
import About from '../../components/about'
import Spreads from '../../components/Spreads'
import Symtoms from '../../components/symsoms'
import Prevention from '../../components/prevention'
import WorldMap from '../../components/WorldMap'
import TeamSection from '../../components/team'
import BlogSection from '../../components/Blog'
import FooterSection from '../../components/footer'

const Homepage = () => {
    return(
       <div>
           <Header/>
           <Hero1/>
           <About/>
           <Spreads/>
           <Symtoms/>
           <Prevention/>
           <WorldMap/>
           <TeamSection/>
           <BlogSection/>
           <FooterSection/>
       </div>
    )
}

export default Homepage;