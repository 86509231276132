import React from 'react'
import {Link} from 'react-router-dom'
import blog1 from '../../images/blog/01.jpg'
import BlogSidebar from '../BlogSidebar'
import './style.css'

const BlogLeft = () => {
    return(
        <section className="blog blog-sidebar">
            <div className="container">
                <div className="row">
                <div className="col-lg-4 order-lg-0 order-1">
                        <BlogSidebar/>
                    </div>
                    <div className="col-lg-8 order-lg-0 order-0">
                        <div className="row">
                            <div className="col-md-6 mb-4">
                                <div className="single-blog">
                                    <div className="row align-items-center">
                                        <div className="col-lg-12">
                                            <div className="img">
                                                <img src={blog1} alt=""/>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="content mt-3">
                                                <span>05 April 2020</span>
                                                <h5><Link to="/blogdetails">Duis vel tincidunt lectus maurisenim.</Link></h5>
                                                <p>Donec fringlla est ametdis quam quam dictum vitae enim nulla falis Donec quam fringilla Donec fringilla quam.</p>
                                                <Link to="/blogdetails">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 mb-4">
                                <div className="single-blog">
                                    <div className="row align-items-center">
                                        <div className="col-lg-12">
                                            <div className="img">
                                                <img src={blog1} alt=""/>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="content mt-3">
                                                <span>05 April 2020</span>
                                                <h5><Link to="/blogdetails">Duis vel tincidunt lectus maurisenim.</Link></h5>
                                                <p>Donec fringlla est ametdis quam quam dictum vitae enim nulla falis Donec quam fringilla Donec fringilla quam.</p>
                                                <Link to="/blogdetails">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 mb-4">
                                <div className="single-blog">
                                    <div className="row align-items-center">
                                        <div className="col-lg-12">
                                            <div className="img">
                                                <img src={blog1} alt=""/>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="content mt-3">
                                                <span>05 April 2020</span>
                                                <h5><Link to="/blogdetails">Duis vel tincidunt lectus maurisenim.</Link></h5>
                                                <p>Donec fringlla est ametdis quam quam dictum vitae enim nulla falis Donec quam fringilla Donec fringilla quam.</p>
                                                <Link to="/blogdetails">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 mb-4">
                                <div className="single-blog">
                                    <div className="row align-items-center">
                                        <div className="col-lg-12">
                                            <div className="img">
                                                <img src={blog1} alt=""/>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="content mt-3">
                                                <span>05 April 2020</span>
                                                <h5><Link to="/blogdetails">Duis vel tincidunt lectus maurisenim.</Link></h5>
                                                <p>Donec fringlla est ametdis quam quam dictum vitae enim nulla falis Donec quam fringilla Donec fringilla quam.</p>
                                                <Link to="/blogdetails">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 mb-md-0 mb-4">
                                <div className="single-blog">
                                    <div className="row align-items-center">
                                        <div className="col-lg-12">
                                            <div className="img">
                                                <img src={blog1} alt=""/>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="content mt-3">
                                                <span>05 April 2020</span>
                                                <h5><Link to="/blogdetails">Duis vel tincidunt lectus maurisenim.</Link></h5>
                                                <p>Donec fringlla est ametdis quam quam dictum vitae enim nulla falis Donec quam fringilla Donec fringilla quam.</p>
                                                <Link to="/blogdetails">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="single-blog">
                                    <div className="row align-items-center">
                                        <div className="col-lg-12">
                                            <div className="img">
                                                <img src={blog1} alt=""/>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="content mt-3">
                                                <span>05 April 2020</span>
                                                <h5><Link to="/blogdetails">Duis vel tincidunt lectus maurisenim.</Link></h5>
                                                <p>Donec fringlla est ametdis quam quam dictum vitae enim nulla falis Donec quam fringilla Donec fringilla quam.</p>
                                                <Link to="/blogdetails">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <ul className="post-pagin">
                                    <li><Link to="/blogleft"><i className="fa fa-angle-left"></i></Link></li>
                                    <li><Link to="/blogleft">1</Link></li>
                                    <li className="active"><Link to="/blogleft">2</Link></li>
                                    <li><Link to="/blogleft">3</Link></li>
                                    <li><Link to="/blogleft">4</Link></li>
                                    <li><Link to="/blogleft"><i className="fa fa-angle-right"></i></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default BlogLeft;