import React from 'react'
import './style.css'

const LiveMAp = () => {
    return(
        <div className="live-update">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="">
                            <iframe  width="100%" height="600" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://www.arcgis.com/apps/opsdashboard/index.html#/bda7594740fd40299423467b48e9ecf6"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default LiveMAp;