import React from 'react'
import {Link} from 'react-router-dom'
import abimg from '../../images/about/Vector.png'
import './style.scss'

const About = () => {
    return(
        <div className="about-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-7">
                        <div className="about-img">
                            <img src={abimg} alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="about-text">
                            <h5>About Corona</h5>
                            <h2>We Should Know About <span>Covid-19</span> (corona) so that We Can prevent it.</h2>
                            <p>Duis vel tincidunt lectus. Vestibulum condimentum mauris enim, feugiat aliquet lacus venenatis eget.</p>
                            <p>Donec fringilla est sit amet quam mollis dictum ac vitae enim. Nulla facilisi. Cras molestie, tellus id luctus molestie, felis nunc condimentum nulla, at vehicula nibh nulla ac ante. Quisque ultrices justo liberomaximus tristique quam volutpat quis. Etiam laoreet vel quam non ornare.</p>
                            <Link to='/' className="theme-btn">Read More</Link>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}
export default About;