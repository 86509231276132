import React from 'react'
import {Link} from 'react-router-dom'
import './style.css'

const PageTitle = ( props ) => {
    return(
        <section className="page-title">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <h1>{props.subtitle}</h1>
                        <ul>
                            <li><Link to='/'>Home</Link></li>
                            <li>{props.pgtitle}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default PageTitle;